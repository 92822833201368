.breadcrumb {

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 60px;
    }

    li {
        display: flex;
        align-items: center;
        position: relative;
        color: $color-white;
        padding: 0 15px;
        font-size: 1.125rem;
        line-height: 1.375rem;

        &:first-child {
            padding-left: 0;
        }

        & svg {
            position: absolute;
            width: 20px;
            height: 20px;
            fill: $color-third;
            margin: auto;
            right: -8px;
            bottom: 0;
            top: 0;
            transform: rotate(-90deg);
            pointer-events: none;
        }

        &:hover, &:focus {
            color: $color-second;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;
        margin-bottom: 20px;

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
            padding: 0 30px 0 0;

            & svg {
                right: 5px;
            }
        } 
    }

}
