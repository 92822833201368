//======================================================================================================
// Actions pages
//======================================================================================================
@keyframes show-hide {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.actions-post {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 0;
    margin: 0;
    height: 205px;
    padding-top: 145px;
    background-color: $color-main;

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        height: 190px;
        background-image: url("/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Vague-actionpost.svg");
        background-repeat: no-repeat;
        background-position-y: -110px;
        background-size: cover;
        pointer-events: none;
    }

    li {
        position: relative;

        a, button, div {
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 60px;
            margin: 0;
            padding: 0 30px;
            background-color: transparent;
            border: none;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            color: $color-white;
            transition: all $duration ease-in-out;

            svg {
                width: 30px;
                height: 30px;
                margin-right: 10px;
                fill: $color-main;
                background-color: $color-white;
                border-radius: $border-radius--round;
                transition: all $duration ease-in-out;
            }

            &:hover {
                color: $color-sixth;
                background-color: $color-bg--transparent;
                border-color: $color-bg--transparent;

                svg {
                    fill: $color-sixth;
                    background-color: $color-bg--transparent;
                }
            }


        }

        &:last-child a,
        &:last-child button {
            border-right: 0;
            transition: none;
        }

    }

    .share-list {
        display: none;
    }

    &.shared-on {

        .action-share {
            opacity: 0;
        }

        .share-list {
            display: flex;
            position: absolute;
            top: 10px;
            left: 30px;

            li {
                margin-right: 10px;
            }

            a, button, div {

                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                width: 40px;
                height: 40px;
                padding: 0;
                background-color: $color-white;
                border: 0;
                border-radius: $border-radius--round;
                box-shadow: $shadow;
                opacity: 1;
                transition: all $duration ease-in-out!important;

                &:hover, &:focus {
                    background-color: $color-sixth;

                    svg {
                        fill: $color-main;
                    }

                }

                svg {
                    fill: $color-main;
                    background-color: $color-bg--transparent;
                    margin: 0;
                    transform: scale(1.3);
                }

                &.close {
                    svg {
                        transform: scale(1.5);
                        // stroke: $color-main;
                        fill: $color-main;
                    }
                    &:hover, &:focus {
                        svg {
                            fill: $color-main;
                            // stroke: $color-main;
                        }
                    }
                }

                #post_url {
                    position: absolute;
                    opacity: 0;
                }
            }

            .copy-confirm {
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 35px;
                left: 50%;
                z-index: 2;
                width: 105px;
                height: 30px;
                opacity: 0;
                background: $color-gray;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: none;
                color: $color-white;
                transform: translate(-50%, 0);

                &.show-hide {
                    animation : show-hide 2s;
                }
            }

        }
    }

}

section[data-cpt=maps] .actions-post {
    margin-top: 0;
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .actions-post {
        &.shared-on {
            margin: 60px auto 20px;

            .share-list {
                display: flex;
                position: absolute;
                top: -50px;
                left: -120px;
                filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15));
            }

            .action-share {
                opacity: 1;
            }

        }
    }

}


// 640
@media screen and (max-width: $small) {

    .actions-post {
        align-items: flex-end;
        padding-top: 120px;
        height: 120px;

        &:before {
            background-position-y: -56px;
            background-size: contain;
        }

        > li {
            > a, button, div {
                justify-content: center;
                width: 40px;
                height: 40px;
                margin: 0 20px;
                padding: 0;
                background: $color-second;
                border-radius: 50px;

                span {
                    display: none;
                }
                svg {
                    background-color: $color-bg--transparent;
                    fill: $color-white;
                    margin: 0;
                }

                &:hover, &:focus, &:active {
                    background: $color-main;
                    opacity: .8;
                    svg {
                        fill: $color-white;
                    }
                }
            }

            &:last-child {
                button {
                    transition: all $duration ease-in-out;
                }
            }
        }

        &.shared-on {
            margin: 0 auto;

            .share-list {
                left:-185px;

                button {
                    margin: 0;

                    svg {
                        fill: $color-main;
                    }
                }
            }
        }

    }

}
