.footer {
    background-color: $color-main;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__titles {
        color: $color-white;
    }

    &__logo {
        width: 225px;
        margin: 70px 0 85px;
        text-align: center;

        &__home {
            display: inline-block;
            margin-bottom: 20px;

            & svg {
                opacity: 1;
                transition: all $duration ease-in-out;
            }

            &:hover {
                & svg {
                    opacity: .5;
                }
            }
        }

        &__button {
            margin: 0;
            width: 100%;
            height: 50px;
            padding: 10px auto;
            color: $color-text;
            border-color: $color-fifth;
            background-color: $color-fifth;

            & svg {
                fill: $color-text;
                margin-right: 10px;
            }

            &:hover, &:focus {
                background-color: $color-white;
                border-color: $color-white;
            }
        }
    }

    &__infos {
        display: flex;
        color: $color-white;
        margin: 80px 0;

        &__coordonnees {
            width: 295px;
            margin-right: 105px;
        }

        &__horaires {
            width: 370px;
            margin-right: 15px;
        }

        &__title {
            font-size: 1.25rem;
            line-height: 1.5rem;
            margin-bottom: 25px;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;

            & br {
                display: none;
            }
        }

        &__text {
            font-size: 1.125rem;
            line-height: 1.375rem;
        }

        &__phone {
            display: inline-block;
            font-size: 1.125rem;
            line-height: 1.375rem;
            color: $color-white;
            font-weight: $font-weight-bold;
            margin-top: 25px;
            opacity: 1;

            &:hover {
                color: $color-white;
                opacity: .5;
            }
        }
    }

    &__partenaire {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 85px;

        &__link {
            display: inline-block;

            & img {
                width: 85px;
                height: 85px;
                opacity: 1;
                transition: all $duration ease-in-out;
            }

            &:first-child {
                & img {
                    height: 60px;
                }
            }

            &:hover {
                & img {
                    opacity: .5;
                }
            }
        }
    }

    &__menu {
        padding: 30px 0;
        background: $color-white;

        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        li {
            position: relative;
            padding: 0 34px;
            font-size: 1rem;
            line-height: 1.375rem;

            a {
                &:hover {
                        color: $color-second;
                }
            } 

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: -4px;
                width: 8px;
                height: 8px;
                background: $color-text;
                transform: translateY(-50%);
                border-radius: $border-radius--round;
            }

            &:first-child::before {
                content: '';
                position: absolute;
                top: 50%;
                left: -4px;
                width: 8px;
                height: 8px;
                background: $color-text;
                transform: translateY(-50%);
                border-radius: $border-radius--round;
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {

        &__logo {
            width: 210px;
        }

        &__infos {

            &__title {
                & br {
                    display: unset;
                }
            }

            &__coordonnees {
                width: 240px;
                margin-right: 20px;
            }

            &__horaires {
                width: 290px;
                margin-right: 45px;
            }
        }

        &__menu {
            li {
                padding: 0 20px;
                
                &:before {
                    left: -2px;
                }

                &:after {
                    right: -2px;
                }
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .footer {

        &__container {
            position: relative;
            flex-direction: column;
            align-items: flex-start;
        }

        &__logo {
            width: 300px;
            margin: 70px 0 50px;
        }

        &__infos {
            margin: 0 0 80px;

            &__coordonnees {
                position: absolute;
                top: 80px;
                margin: 0;
                right: 10px;
                width: 300px;
                text-align: center;
            }

            &__horaires {
                width: 300px;
                margin-right: 20px;
                text-align: center;
            }
        }

        &__partenaire {
            width: 300px;
        }

        &__menu {
            & li {
                padding: 0 30px;

                &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                    margin-top: 35px;
                }

                &:nth-child(4) {

                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        width: 8px;
                        height: 8px;
                        background: #282828;
                        transform: translateY(-50%);
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {

    .footer {

        &__logo {
            margin-bottom: 40px;

            &__home {
                & svg {
                    width: 185px;
                    height: 85px;
                }
            }
        }

        &__infos {
            flex-direction: column;

            &__coordonnees {
                position: initial;
                margin-bottom: 40px;
            }

            &__horaires {
                margin-bottom: 40px;
                margin-right: 0;
            }
        }

        &__partenaire {
            flex-direction: row;
            justify-content: center;
            height: 85px;

            &__link {
                &:first-child {
                    margin-right: 15px;
                }
            }
        }

        &__menu {
            & li {

                &:after {
                    right: -4px;
                }

                &:nth-child(1) {
                    padding: 0 15px;
                }

                &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                    padding: 0 15px;
                    margin-top: 30px;
                }

                &:nth-child(5), &:nth-child(6) {
                    padding: 0 15px;
                    margin-top: 30px;
                }

                &:nth-child(2), &:nth-child(3), &:nth-child(5) {
                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        width: 8px;
                        height: 8px;
                        background: #282828;
                        transform: translateY(-50%);
                        border-radius: 50%;
                    }
                }

                &:nth-child(4) {
                    &:before {
                        content: none;
                    }
                }
            }
        }
    }
}