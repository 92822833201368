header {
    position: relative;
    margin-bottom: 180px;
}

.header-top {
    position: absolute;
    z-index: 15;
    top: 0;
    width: 100%;
    height: 120px;
    background-color: $color-second;
    box-shadow: unset;
    transition: all $duration ease-in-out;

    .container--identity {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 120px;
        transition: all $duration ease-in-out;

        .site-identity {
            display: flex;
            align-items: center;
        }

        .identity {
            &__title {
                font-size: 0;
                line-height: 0;

                &:hover, &:focus, &:focus-within {
                    cursor: pointer;
                }
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }
        
        &::before {
            background: $color-bg--neutral;
        }
    }
}

.tools {

    .container--tools {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
    }

    .tool {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        border: 0;
        transition: all $duration;
        
        svg {
            transition: all $duration;
        }

        &:hover, &:focus, &:focus-within {
            cursor: pointer;
        }
    }

    &__search {
        margin: 0 15px 0 0;
        color: $color-white;
        background-color: $color-dark;
        padding: 10px 20px;
        transition: all $duration ease-in-out;

        svg {
            width: 30px;
            height: 30px;
            margin-right: 5px;
            fill: $color-white;
        }

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }

        &:hover, &:focus, &:focus-within {
            background-color: $color-white!important;
            border-color: $color-white!important;
            color: $color-text!important;

            svg {
                fill: $color-text!important;
            }
        }
    }

    &__accessibility {
        margin: 0;
        padding: 0;
        width: 50px;
        background-color: $color-sixth;
        transition: all $duration ease-in-out;
        svg {
            width: 40px;
            height: 40px;
            fill: $color-dark;
        }
    }
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover, &:focus, &:focus-within {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}

//Header fixed
.header.header--fixed {
    .header-top {
        position: fixed;
        height: 100px;
        top: 0;
        box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.20);

        .container {
            &--identity {
                height: 100px;
            }
        }

        #nav-main .nav-main-item .nav-main-link {
            height: 100px;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .header-top {

        .tools {

            &__search {
                font-size: 0;
                padding: 0;
                width: 50px;
                height: 50px;

                & svg {
                    margin-right: 0;
                }
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    header {
        margin: 0;
    }

    .header-top {
        position: relative;
        top: unset;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    .header-top {
        position: relative;
        height: 220px;
        background-color: $color-bg--transparent;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            height: 120px;
            top: 0;
            left: 0;
            right: 0;
            background-color: $color-second;
        }

        .container {
            &--identity {
                flex-wrap: wrap;
                height: 220px;

                .site-identity {
                    width: 100%;
                    height: 120px;
                    margin: 0 auto;
                    justify-content: center;
                }
            }
        }
    }

    .tools {
        &__content {
            height: 100px;
            align-items: center;
        }
    }
}
