.cover {
    position: relative;
    margin-top: 50px;
    margin-bottom: 40px;

    .shape-container {
        position: relative;
        z-index: 0;
        height: 525px;
        margin-bottom: 50px;

        &:before {
            content: "";
            position: absolute;
            z-index: -2;
            top: 35px;
            right: 0;
            width: 539px;
            height: 485px;
            background-image: url('/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Bulle-legende-alu.svg');
            background-size: contain;
        }

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            opacity: .3;
            top: 120px;
            left: 575px;
            width: 140px;
            height: 140px;
            background-image: url('/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Cercles-yellow.svg');
            background-size: contain;
        }
    }

    &__container {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            opacity: .3;
            top: 205px;
            left: -55px;
            width: 330px;
            height: 330px;
            background-image: url('/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Cercles.svg');
            background-size: contain;
        }
    }

    &__informations {
        background: $color-main;
        padding: 30px 0 50px;
    }

    &__title {
        position: absolute;
        width: 420px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: fit-content;
        text-align: center;
        right: calc(50% - 525px);
        padding: 0;

        &:before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            left: 0;
            right: 0;
            bottom: -50px;
            margin: 0 auto;
            border-radius: $border-radius--round;
            background-color: $color-second;
        }
    }

    &__lead-wrapper {
        p {
            margin: 0;
            color: $color-white;
            font-size: 1.375rem;
            line-height: 1.5rem;
            font-weight: $font-weight-bold;
        }
    }

    &__category {
        color: $color-white;
    }

    &__intro {
        color: $color-white;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================


// 1200
@media screen and (max-width: $large) {

    .cover {
        margin-top: 60px;

        &__container {
            
            &:before {
                top: 125px;
                left: -90px;
            }

            &:after {
                top: 90px;
                left: 435px;
            }
        }

        &__title {
            width: 360px;
            left: calc(50% + 450px);
        }

        .shape-container {
            height: 420px;
            margin-bottom: 60px;

            & svg {
                width: 560px;
                height: 420px;
            }

            &:before {
                width: 430px;
                height: 390px;
                background-repeat: no-repeat;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .cover {

        &__container {
            &:before {
                width: 290px;
                height: 290px;
            }
        }

        &__title {
            font-size: 1.25rem;
            line-height: 1.5rem;
            left: calc(50% - 210px);
            bottom: unset;
            width: 250px;
            top: 90px;

            &:before {
                width: 15px;
                height: 15px;
                bottom: -30px;
            }
        }

        &__lead-wrapper {
            p {
                font-size: 1.125rem;
                line-height: 1.375rem;
                font-weight: $font-weight-semibold;
            }
        }

        .shape-container {
            margin-bottom: 70px;

            &::before {
                width: 300px;
                height: 270px;
                z-index: 1;
                right: 10px;
                top: 0;
            }

            &:after {
                z-index: 0;
                top: 200px;
                left: 475px;
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        margin-top: 0;

        &__title {
            width: 230px;
            top: 360px;
            right: 0;
            left: 0;
        }

        .shape-container {
            height: 545px;
            margin-bottom: 50px;

            & svg {
                width: 400px;
                height: 300px;
                margin-left: -50px;
            }

            &:before {
                width: 310px;
                height: 279px;
                top: unset;
                bottom: 0;
                right: 0;
                left: 0;
            }

            &:after {
                top: 225px;
                left: 230px;
            }
        }
    }

}
