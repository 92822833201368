.front-page {

    &__section {
        &__container {
            display: flex;
            justify-content: space-between;
        }
    }

    .home{

        &__title {
            &:before {
                content: none;
            }
        }
        
        &__button {
            & svg {
                margin-right: 10px;
            }
        }
    }
}


//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;
    margin: 60px 0 70px;

    &:before {
        content: "";
        position: absolute;
        width: 333px;
        height: 333px;
        bottom: -45px;
        left: calc(50% - 635px);
        background-image: url('/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Cercles.svg');
        opacity: .3;
    }

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--navigation {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            pointer-events: none
        }
    }

    .slideshow {
        height: 525px;
        width: 100%;

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }

        &__logo {
            position: absolute;
            width: 245px;
            height: 115px;
            left: 625px;
            top: 110px;

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                width: 320px;
                height: 305px;
                top: -110px;
                left: -40px;
                background-image: url('/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Bulle-logo.svg');
            }
            
            &:after {
                content: "";
                position: absolute;
                z-index: -2;
                width: 140px;
                height: 140px;
                top: -15px;
                left: 225px;
                background-image: url('/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Cercles-yellow.svg');
                background-size: contain;
                opacity: .3;
            }

            &__container {
                position: relative;
                z-index: 2;
                width: 100%;
                height: 0;
            }
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            text-align: center;
            right: 45px;
            bottom: 80px;
            width: 295px;
            min-height: 120px;
            color: $color-text;

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                bottom: -80px;
                left: -60px;
                width: 400px;
                height: 360px;
                background-image: url('/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Bulle-legende-alu.svg');
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        &__title {
            position: relative;
            z-index: 0;
            margin-bottom: 40px;
            font-size: 1.5rem;
            line-height: 1.75rem;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
        
            &:before {
                content: "";
                position: absolute;
                width: 10px;
                height: 10px;
                left: 0;
                right: 0;
                bottom: -25px;
                margin: 0 auto;
                background-color: $color-second;
                border-radius: $border-radius--round;
            }
        }

        &__description {
            font-size: 1.125rem;
            line-height: 1.375rem;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Navigation (arrow)
        &__navigation {
            position: absolute;
            left: 315px;
            width: 70px;
            height: 30px;
            pointer-events: none;

            &__container {
                position: absolute;
                width: 100%;
                height: 30px;
                top: 470px;
                left: 0;
                right: 0;
            }
    
            &__btn {
                pointer-events: auto;
                top: 0;
                width: 30px;
                height: 30px;
                margin: 0;
                padding: 0;
                background: $color-third;
                border: 0;
                border-radius: 50px;

                & svg {
                    margin: 0;
                }
    
                &::after {
                    content: none;
                }
    
                &:hover, &:focus, &:focus-within {
                    background-color: $color-main;

                    & svg {
                        fill: $color-white;
                    }
                }

                &--prev {
                    left: 0;
                }

                &--next {
                    right: 0;
                }
            }
        }
    }
}


//======================================================================================================
// Access
//======================================================================================================
.access {
    &__section {
        position: relative;
        z-index: 0;
        height: 250px;
        background-color: $color-main;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 100%;
            height: 190px;
            background-image: url("/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Vague-accesrapides2.svg");
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: cover;
            opacity: .1;
        }
    }

    &__container {
        display: flex;
        justify-content: center;
    }

    &__list {
        display: flex;
        width: 1100px;
        height: 110px;
        margin: 90px 0 50px;
        align-items: center;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 0;

        &:before {
            content: "";
            position: absolute;
            z-index: 0;
            top: -32px;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 2px;
            width: 950px;
            background-color: $color-white;
        }
    }

    &__link {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 110px;
        width: 150px;
        position: relative;
        z-index: 0;
        transition: all $duration ease-in-out;
        line-height: 1.375rem;
        text-transform: lowercase;
        font-weight: $font-weight-bold;
        color: $color-white;

        &:before {
            content: "";
            position: absolute;
            width: 15px;
            height: 15px;
            top: -39px;
            left: 0;
            right: 0;
            margin: 0 auto;
            border-radius: $border-radius--round;
            background-color: $color-white;
            transition: all $duration ease-in-out;
        }

        & svg {
            fill: $color-white;
        }

        &:hover, &:focus, &:focus-within {
            color: $color-white;

            & svg {
                fill: $color-fifth;
            }

            /*& svg path[fill=white] {
                fill: $color-fifth;
            }*/

            &:before {
                width: 65px;
                border-radius: 50px;
            }
        }
    }
}


//======================================================================================================
// Post
//======================================================================================================
.post {

    .slideshow {
        &__navigation {
            position: absolute;
            top: -70px;
            right: 50px;
            width: 70px;
            height: 30px;

            &__container {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    top: 325px;
                    left: -70px;
                    height: 305px;
                    width: 320px;
                    background-image: url('/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Bulle-actu.svg');
                    opacity: .5;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: -115px;
                    right: -65px;
                    height: 265px;
                    width: 265px;
                    background-image: url('/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Cercles.svg');
                    background-size: contain;
                    opacity: .3;
                }
            }

            &__btn {
                width: 30px;
                height: 30px;
                top: 0;
                margin: 0;
                padding: 0;

                & svg {
                    width: 30px;
                    height: 30px;
                    margin: 0;
                }

                &--prev {
                    left: 0;
                }

                &--next {
                    right: 0;
                }

                &:after {
                    content: none;
                }

                &:hover, &:focus, &:focus-within {
                    background-color: $color-third;
                    border-color: $color-third;
                }
            }
        }
    }

    .swiper {
        &-container {
            width: 1110px;
            height: 580px;
            padding: 0 20px;
        }

        &-wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        &-slide {
            width: 500px !important;
        }
    }

    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 3.125rem;
        line-height: 3.438rem;
        color: $color-main;
        text-transform: lowercase;
        margin: 60px 0 40px;
        padding: 0;

        & svg {
            width: 140px;
            height: 10px;
            fill: $color-main;
            margin-top: 15px;
        }
    }

    &__link {
        display: inline-block;
        height: 560px;
        background-color: $color-light;
        box-shadow: unset;
        transition: all $duration ease-in-out;
        
        &:hover, &:focus, &:focus-within {
            background-color: $color-white;
            box-shadow: $shadow;
        }
    }

    &__content {
        height: 300px;

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-bg--image;
            background-position: center;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__infos {
        height: 260px;
        padding: 30px 15px 35px 40px;
        box-sizing: border-box;

        &__title {
            font-size: 2rem;
            line-height: 2.375rem;
            margin: 0 0 15px;
        }

        &__introduction {
            font-size: 1.125rem;
            line-height: 1.375rem;
            margin: 0 0 15px;
        }

        &__category {
            position: relative;
            font-size: 1.125rem;
            line-height: 1.375rem;
            font-style: italic;
            padding: 0;
            margin: 0 0 0 25px;

            &:before {
                content: "";
                position: absolute;
                height: 15px;
                width: 15px;
                top: 0;
                bottom: 0;
                left: -25px;
                margin: auto;
                border-radius: $border-radius--round;
            }

            &--main {
                &:before {
                    background-color: $color-second;
                }
            }

            &--secondary {
                &:before {
                    background-color: $color-fourth;
                }
            }

            &--tertiary {
                &:before {
                    background-color: $color-sixth;
                }
            }
        }
    }

    &__buttons {
        text-align: center;
        margin: 20px 0 60px;
    }

    &__button {
        width: 300px;
        height: 50px;
        margin: 0;
        transition: all $duration ease-in-out;

        & svg {
            transition: all $duration ease-in-out;
        }
    }
}


//======================================================================================================
// Event
//======================================================================================================
.events {
    &__section {
        position: relative;
        z-index: 0;
        padding: 60px 0 115px;
        background-color: $color-second;

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 100%;
            height: 115px;
            background-image: url("/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Vague-agenda.svg");
            background-repeat: no-repeat;
            background-position-y: 35px;
            background-size: cover;
        }

        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            height: 1030px;
            width: 458px;
            top: -50px;
            right: calc(50% - 858px);
            background-image: url("/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/filigrane.svg");
        }
    }

    &__container {
        display: flex;
        height: 570px;
        column-gap: 30px;
    }

    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 3.125rem;
        line-height: 3.438rem;
        color: $color-white;
        text-transform: lowercase;
        margin: 0 0 40px;
        padding: 0;

        & svg {
            width: 140px;
            height: 10px;
            fill: $color-white;
            margin-top: 15px;
        }
    }

    &__link {
        display: flex;
        width: 370px;
        flex-direction: column;
        background-color: $color-light;
        box-shadow: unset;
        transition: all $duration ease-in-out;
        
        &:hover, &:focus, &:focus-within {
            background-color: $color-white;
            box-shadow: $shadow;
        }
    }

    &__date {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;

        color: $color-main;
        font-size: 1.875rem;
        line-height: 1.875rem;
        text-transform: uppercase;
        font-family: $font-family;
        font-weight: $font-weight-bold;

        & p {
            &:nth-child(2) {
                position: relative;
                margin-left: 40px;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: -25px;
                    margin: auto;
                    width: 10px;
                    height: 10px;
                    background-color: $color-main;
                    border-radius: $border-radius--round;
                }
            }
        }
    }

    &__content {
        height: 300px;

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-bg--image;
            background-position: center;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__infos {
        height: 170px;
        padding: 25px;

        &__title {
            font-size: 2rem;
            line-height: 2.375rem;
            margin: 0 0 15px;
        }

        &__category {
            position: relative;
            font-size: 1.125rem;
            line-height: 1.375rem;
            font-style: italic;
            padding: 0;
            margin: 0 0 0 25px;

            &:before {
                content: "";
                position: absolute;
                height: 15px;
                width: 15px;
                top: 0;
                bottom: 0;
                left: -25px;
                margin: auto;
                border-radius: $border-radius--round;
            }

            &--main {
                &:before {
                    background-color: $color-second;
                }
            }

            &--secondary {
                &:before {
                    background-color: $color-fifth;
                }
            }

            &--tertiary {
                &:before {
                    background-color: $color-third;
                }
            }
        }
    }

    &__buttons {
        margin: 40px 0 15px;
        text-align: center;
    }

    &__button {
        width: 300px;
        height: 50px;
        margin: 0;
        padding: 10px auto;
        transition: all $duration ease-in-out;
        
        & svg {
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus, &:focus-within {
            background-color: $color-white;
            border-color: $color-white;
            color: $color-text;
            

            & svg {
                fill: $color-text;
            }
        }

        &:first-child {
            color: $color-text;
            border-color: $color-white;
            background-color: $color-white;
            margin-right: 20px;

            & svg {
                fill: $color-text;
                margin-right: 10px;
            }

            &:hover, &:focus, &:focus-within {
                color: $color-white;
                border-color: $color-dark;
                background-color: $color-dark;

                & svg {
                    fill: $color-white;
                }
            }
        }
    }
}


//======================================================================================================
// Kiosque
//======================================================================================================
.kiosque {

    &__section {
        width: 570px;
    }

    &__container {
        position: relative;
        z-index: 0;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: -110px;
            left: -120px;
            width: 265px;
            height: 265px;
            background-image: url("/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Cercles.svg");
            background-size: contain;
            opacity: .3;
        }
    }

    &__heading {
        display: inline-block;
        margin-left: 50px;

        &__container {
            position: relative;
            z-index: 1;
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
    }

    &__image {
        width: 270px;
        height: 380px;
        margin-right: 30px;
        box-shadow: 1px 2px 10px rgba(0,0,0,.15);

        &--no-image {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-bg--image;
        
            svg {
              width: 40%;
            }
        }

        & img {
            height: 380px;
            width: 100%;
            object-fit: cover;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 380px;
        width: 270px;
    }
    
    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 3.125rem;
        line-height: 3.438rem;
        color: $color-main;
        text-transform: lowercase;
        margin: 0 0 40px;
        padding: 0;

        & svg {
            width: 140px;
            height: 10px;
            fill: $color-main;
            margin-top: 15px;
        }
    }

    &__info {
        &__title {
            font-size: 1.5rem;
            line-height: 2rem;
            margin: 0;
        }

        &__content {
            font-style: italic;
        }
    }

    &__filesize {
        text-transform: uppercase;
    }

    &__date {
        display: block;
    }

    &__btn {
        display: flex;
        align-items: center;
        color: $color-text;
        font-size: 1.125rem;
        line-height: 1.375rem;
        font-weight: $font-weight-bold;
        font-family: $font-family--heading;
        margin-bottom: 10px;

        & svg {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            fill: $color-white;
            background-color: $color-second;
            border-radius: $border-radius--round;
        }

        &:hover, &:focus, &:focus-within {
            & svg {
                background-color: $color-main;
            }
        }
    }

    &__button {
        margin: 0;
        height: 50px;
    }
}


//======================================================================================================
// Social
//======================================================================================================
.social {

    &__section {
        width: 530px;
    }

    &__container {
        position: relative;
        z-index: 1;

        &__sw {
            position: relative;
            z-index: 0;

            &:before, &:after {
                content: "";
                position: absolute;
                background-size: contain;
                z-index: -1;
            }

            &:before {
                top: -125px;
                right: -50px;
                width: 228px;
                height: 205px;
                background-image: url("/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Bulle-sw.svg");
                opacity: .7;
            }

            &:after {
                width: 144px;
                height: 144px;
                right: -70px;
                bottom: -60px;
                background-image: url("/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Cercles-orange.svg");
                opacity: .3;
            }
        }
    }
    
    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 3.125rem;
        line-height: 3.438rem;
        color: $color-main;
        text-transform: lowercase;
        margin: 0 0 40px;
        padding: 0;

        & svg {
            width: 140px;
            height: 10px;
            fill: $color-main;
            margin-top: 15px;
        }
    }

    &__rs {
        position: absolute;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 110px;
        height: 30px;
        top: 55px;
        right: 0;

        &__link {
            display: inline-block;
            height: 30px;
            width: 30px;
            background-color: $color-dark;
            border-radius: $border-radius--round;
            transition: all $duration ease-in-out;

            & svg {
                width: 30px;
                height: 30px;
                fill: $color-white;
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus, &:focus-within {
                background-color: $color-third;
            }
        }

        &__item {
            height: 30px;
        }
    }

    #ff-stream-1.ff-stream {
        width: 530px!important;
        height: 380px!important;
        min-height: 380px!important;
        background: $color-bg--transparent!important;
        padding: 0!important;

        .ff-stream-wrapper {
            width: 560px!important;
            margin-left: -15px!important;
            padding: 0 !important;
            background: transparent!important;
        }

        .ff-item-cont {
            height: 380px!important;
            background-color: $color-light!important;
        }

        .ff-item {
            .ff-img-holder {
                height: 170px!important;
            }

            .ff-item-bar {
                display: none;
            }

            .picture-item__inner {
                border-radius: 0!important;
                box-shadow: unset!important;

                .ff-img-holder {
                    border-radius: 0!important;
                }
            }
        }

        .ff-label-wrapper {
            display: none!important;
        }

        .ff-loadmore-wrapper {
            display: none;
        }
    }
}


//======================================================================================================
// Newsletter
//======================================================================================================
.newsletter {

    &__section {
        margin: 80px 0;
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & span {
            width: 420px;
            font-size: 1.125rem;
            line-height: 1.375rem;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        font-size: 3.125rem;
        line-height: 3.125rem;
        color: $color-main;
        text-transform: lowercase;
        margin: 0;
        padding: 0;

        & svg {
            width: 140px;
            height: 25px;
            fill: $color-main;
            margin-left: 35px;
        }
    }

    &__button {
        height: 50px;
        width: 235px;
        margin: 0 0 0 30px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    // ALU
    .home-slideshow {
        margin: 60px 0 0;
        padding-bottom: 50px;

        &:before {
            width: 330px;
            height: 330px;
            bottom: 50px;
            left: calc(50% - 560px);
            background-size: contain;
        }

        .slideshow {
            height: 455px;

            &__logo {
                width: 195px;
                height: 92px;
                left: 500px;
                top: 90px;

                & svg {
                    width: 195px;
                    height: 92px;
                }

                &:before {
                    top: -90px;
                    left: -35px;
                    width: 255px;
                    height: 245px;
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                &:after {
                    top: -30px;
                    left: 165px;
                    pointer-events: none;
                }
            }

            &__content {
                right: 70px;
                bottom: 60px;
                width: 250px;
                background-size: contain;

                &:before {
                    bottom: -60px;
                    width: 360px;
                    height: 325px;
                }
            }

            .shape-container {
                & svg {
                    width: 560px;
                    height: 420px;
                }
            }

            &__navigation {
                left: 260px;

                &__container {
                    top: 365px;
                }
            }
        }
    }

    //Access
    .access {
        &__section {

            &:before {
                height: 152px;
            }
        }

        &__list {
            &:before {
                width: 800px;
            }
        }
    }

    //Post
    .post {

        .swiper {
            &-container {
                width: 900px;
                height: 560px;
            }

            &-slide {
                width: 400px !important;
            }
        }

        .slideshow {
            &__navigation {
                right: 40px;
            }
        }

        &__link {
            height: 540px;
        }

        &__content {
            height: 240px;
        }

        &__infos {
            padding: 20px 15px 25px 30px;
        }
    }

    //Events
    .events {

        &__section {
            padding-bottom: 92px;

            &:before {
                height: 92px;
            }

            &:after {
                right: calc(50% - 722px);
            }
        }

        &__container {
            height: 555px;
            column-gap: 20px;
        }

        &__link {
            width: 300px;
        }

        &__content {
            height: 245px;
        }
    }

    //Kiosque
    .kiosque {

        &__section {
            width: 470px;
        }

        &__image {
            width: 220px;
            height: 305px;
            margin-right: 20px;

            & img {
                height: 305px;
            }
        }

        &__content {
            height: 305px;
            width: 230px;
        }

        &__buttons {
            display: flex;
        }

        &__btn {
            &__label {
                display: none;
            }
        }
    }

    //Social Wall
    .social {

        &__section {
            width: 440px;
        }

        #ff-stream-1.ff-stream {
            height: 305px !important;
            min-height: 305px !important;
            width: 440px !important;

            .ff-stream-wrapper {
                width: 480px !important;
                height: 305px!important;
                margin-left: -10px !important;
            }

            .ff-item-cont {
                height: 305px!important;
            }

            .ff-item {
                .ff-img-holder {
                    height: 150px!important;
                }
            }
        }
    }

    //Newsletter
    .newsletter {

        &__container {
            & span {
                width: 295px;
                font-size: .875rem;
                line-height: 1.25rem;
            }
        }

        &__title {
            & svg {
                margin-left: 22px;
            }
        }

        &__button {
            width: 210px;
            margin: unset;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .front-page {
        &__section {
            &__container {
                flex-direction: column;
            }
        }
    }

    //ALU
    .home-slideshow {
        position: relative;
        margin: 60px 0 0;
        padding-bottom: 70px;

        &:before {
            width: 290px;
            height: 290px;
            left: calc(50% - 400px);
        }

        .slideshow {
            height: 420px;
            overflow: visible;

            &__logo {

                &__container {
                    display: none;
                }
            }

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }

            &__content {
                z-index: 1;
                width: 230px;
                top: 65px;
                left: unset;
                right: 10px;
                bottom: unset;
                justify-content: center;
                
                &:before {
                    height: 270px;
                    width: 300px;
                    left: -40px;
                    top: -65px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    z-index: -2;
                    width: 140px;
                    height: 140px;
                    bottom: -125px;
                    right: 0;
                    background-image: url(/wp-content/themes/st-pierre-la-cour/assets/src/images/sprite-images/image-home/Cercles-yellow.svg);
                    background-size: contain;
                    opacity: .3;
                }
            }

            &__title {
                font-size: 1.25rem;
                line-height: 1.5rem;
            }

            &__description {
                font-size: .875rem;
                line-height: 1.25rem;
            }
        }
    }

    //Access
    .access {

        &__section {
            height: 435px;
            
            &:before {
                height: 250px;
            }

            /*&:after {
                content: "";
                position: absolute;
                top: 0;
                height: 42px;
                width: 100%;
                left: 0;
                right: 0;
                background-color: #7F9F92;
                opacity: 0.1;
            }*/
        }

        &__list {
            width: 530px;
            height: 295px;
            margin: 90px 0 50px;
            flex-wrap: wrap;

            &:after {
                content: "";
                position: absolute;
                z-index: 0;
                top: 153px;
                left: 0;
                right: 0;
                margin: 0 auto;
                height: 2px;
                background-color: #fff;
            }

            &:before, &:after {
                width: 380px;
            }
        }

        &__item {

            &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                margin-top: 75px;
            }
        }
    }

    //Post
    .post {

        .swiper {
            &-container {
                width: 100%;
                height: 534px;
                padding: 0;
            }

            &-slide {
                width: 300px !important;
            }
        }

        .slideshow {

            &__navigation {
                right: 0;
                
                &__container {
                    
                    &:before {
                        top: 295px;
                        left: -135px;
                        z-index: -1;
                    }

                    &::after {
                        top: -125px;
                        right: -150px;
                        z-index: -1;
                    }
                }
            }
        }

        &__link {
            height: 514px;
        }

        &__content {
            height: 200px;
        }

        &__infos {
            height: auto;
            padding: 20px 15px 25px 20px;

            &__title {
                font-size: 1.563rem;
                line-height: 2.188rem;
                margin: 0 0 5px;
            }
        }
    }

    //Events
    .events {

        &__section {
            padding-bottom: 75px;

            &:before {
                height: 75px;
                background-position-y: 29px;
            }

            &:after {
                content: none;
            }
        }

        &__container {
            display: flex;
            flex-direction: column;
            height: 745px;
        }

        &__link {
            width: 100%;
            height: 235px;
            flex-direction: row;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__date {
            width: 80px;
            height: 235px;
            transform: rotate(-90deg);

            & p {
                display: flex;
                font-size: 1.563rem;
                line-height: 2.375rem;

                &:nth-child(2) {
                    margin-left: 30px;
                    &:before {
                        left: -20px;
                    }
                }
            }

            &__day {
                margin-right: 5px;
            }
        }

        &__content {
            width: 280px;
            height: 235px;
        }

        &__infos {
            width: 260px;
            height: 235px;
        }

        &__buttons {
            &__container {
                font-size: 0;
            }
        }
    }

    //Kiosque
    .kiosque {
        
        &__section {
            width: 100%;
            margin-top: 60px;
        }

        &__container {
            &:before {
                top: -115px;
                left: -90px;
            }
        }

        &__heading {
            width: 100%;
            margin: 0;
        }

        &__image {
            width: 300px;
            height: 425px;

            & img {
                height: 425px;
            }
        }

        &__content {
            height: 365px;
            width: 300px;
            max-height: 425px;
            padding-top: 60px;
        }
    }

    //Social
    .social {

        &__section {
            width: 100%;
            margin-top: 80px;
        }

        &__container {
            &__sw {
                &:before {
                    top: -115px;
                    right: -10px;
                }

                &:after {
                    right: -60px;
                    bottom: -75px;
                }
            }
        }

        &__rs {
            right: 45px;
        }

        #ff-stream-1.ff-stream {
            height: 430px!important;
            min-height: 430px!important;
            width: 100%!important;

            .ff-stream-wrapper {
                width: 100%!important;
                height: 430px!important;
                margin-left: -20px!important;
            }

            .ff-item-cont {
                height: 430px!important;
            }

            .ff-item {
                .ff-img-holder {
                    height: 200px!important;
                }
            }
        }
    }

    //Newsletter
    .newsletter {

        &__container {
            flex-direction: column;
        }

        &__title {
            flex-direction: column;
            margin-bottom: 40px;

            & svg {
                margin: 20px 0 0;
                height: 10px;
            }
        }

        &__button {
            width: 300px;
            margin-top: 30px;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    //ALU
    .home-slideshow {
        margin: 0;
        padding-bottom: 50px;

        &:before {
            left: calc(50% - 295px);
            bottom: unset;
            top: 100px;
        }

        .slideshow {
            height: 546px;

            &__content {
                top: unset;
                bottom: 60px;
                left: 0;
                right: 0;
                width: 230px;
                margin: 0 auto;

                &:before {
                    top: unset;
                }

                &:after {
                    bottom: unset;
                    right: -100px;
                    top: -110px;
                }
            }

            &__navigation {
                left: 0;
                right: 0;
                margin: 0 auto;

                &__container {
                    top: 235px;
                }
            }

            .shape-container {
                width: 400px;
                height: 300px;
                margin-left: -50px;
                
                & svg {
                    width: 400px;
                    height: 300px;
                }
            }
        }
    }

    //Access
    .access {
        &__section {
            height: 620px;

            &:before {
                height: 325px;
            }

            &:after {
                height: 186px;
            }
        }

        &__container {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                z-index: 0;
                margin: 0 auto;
                bottom: 192px;
                right: 0;
                left: 0;
                height: 2px;
                width: 160px;
                background-color: #fff;
            }
        }

        &__list {
            width: 100%;
            height: 480px;
            margin: 90px 0 50px;
            
            &:before, &:after {
                width: 160px;
            }

            &:before {
                top: -34px;
            }

            &:after {
                top: 152px;
            }
        }

        &__item {
            &:nth-child(3) {
                margin-top: 75px;
            }
        }

        &__link {
            width: 140px;

            &:before {
                top: -40px;
            }
        }

        &__info {
            font-size: 0.875rem;
        }
    }

    //Post
    .post {

        .slideshow {

            &__navigation {
                position: relative;
                top: 0;
                left: 0;
                margin: 0 auto 20px;

                &__container {

                    &:before {
                        top: 355px;
                        left: -140px;
                    }
             
                    &:after {
                        top: -70px;
                        right: -120px;
                    }
                }
            }
        }

        .swiper-container {
            padding: 0;
        }
    }

    //Events
    .events {

        &__section {
            &:before {
                height: 50px;
                background-position-y: 19px;
            }
        }

        &__container {
            height: 1705px;
        }

        &__link {
            height: 555px;
            flex-direction: column;
        }

        &__date {width: 100%;
            height: 100px;
            transform: unset;

            p {
                display: initial;
                font-size: 1.875rem;
                line-height: 1.875rem;
            }
        }

        &__content {
            width: 100%;
            height: 245px;
        }

        &__infos {
            width: 100%;
            height: 210px;
        }

        &__buttons {
            margin: 40px 0 65px;
        }

        &__button {
            margin: 0 0 20px;
        }
    }

    //Kiosque
    .kiosque {

        &__container {
            &:before {
                left: -105px;
            }
        }

        &__item {
            flex-direction: column;
        }

        &__content {
            padding-top: 20px;
            height: auto;
            text-align: center;
        }

        &__image {
            margin: 0;
        }

        &__info {
            &__content {
                padding: 15px 0;
            }
        }

        &__buttons {
            margin-bottom: 30px;
            justify-content: center;
        }

        &__btn {
            margin-right: 15px;
            margin-bottom: 0;

            & svg {
                margin: 0;
            }

            &:last-child {
                margin: 0;
            }
        }
    }

    //Social
    .social {

        &__container {
            &__sw {
                &:before {
                    top: -75px;
                    right: 45px;
                    margin: 0 auto;
                }

                &:after {
                    right: -65px;
                    bottom: -70px;
                }
            }
        }

        &__rs {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto 20px;
        }

        #ff-stream-1.ff-stream {
            height: 880px !important;
            min-height: 880px !important;

            .ff-stream-wrapper {
                height: 880px !important;
                width: 340px !important;
            }

            .ff-content {
                max-height: 130px !important;
            }
        }
    }
}